import gql from "graphql-tag";
import { TaxProfileFragment } from './TaxProfile';
import { operationFragment } from 'financial-front';
export const fiscalOperationSelectionFragment = gql`
  fragment FiscalOperationSelection on FiscalOperation {
    id,
    name,
    kind,
    subkind,
    receiver,
    kindOfService,
    canActivate,
    canActivateNfce,
    dfeModel,
    dfeSeries,
    taxDocumentIssuer,
    isFor,
    discardedAt,
    fullPathName,   
    stockLocationId,
    financialOperation{
      ...Operation
    },
    financialOperationId,
    financialOperationChangeId,
    financialOperationAdministrateTaxId,
    financialOperationId,
    stockOperationId,
    issuancePurpose,    
  }
  ${operationFragment}
`;
export const fiscalOperationSelectionForWaybillFragment = gql`
  fragment FiscalOperationSelectionForWaybill on FiscalOperation {
    ...FiscalOperationSelection
     operationTaxProfiles{
      id,
      sequenceNumber,
      taxProfileId,
      taxProfile {
        ...TaxProfile
      }
    }
  }
  ${fiscalOperationSelectionFragment}
  ${TaxProfileFragment}
`;