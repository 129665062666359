import gql from "graphql-tag";
import { paymentConditionFragment } from "./PaymentCondition";
import { paymentMethodFragment } from "./PaymentMethod";
export const NegotiationPaymentFragment = gql`
  fragment NegotiationPayment on NegotiationPayment  { 
    id,
    timeKind,
    time,
    installment,
    gracePeriod,
    condition,
    methodKind,
    valueToPay,
    fees,
    discount,
    total,
    methodFees,
    conditionFees,
    methodDiscount,
    conditionDiscount,
    methodFeesRate,
    conditionFeesRate,
    methodDiscountRate,
    conditionDiscountRate,
    paymentMethodId,
    paymentConditionId,
    paymentMethod {
      ...PaymentMethod
    },    
    paymentCondition {
      ...PaymentCondition
    },    
  }
  ${paymentMethodFragment} 
  ${paymentConditionFragment}   
`;