import gql from "graphql-tag";
export const negotiationAccountFragment = gql`
  fragment NegotiationAccount on NegotiationAccount {
    id,
    negotiatedValue,
    accountId,
    transactionId,
    value,
    balance,
    kind,
    shortComplement
  }
`;