import { FLEET, CONFIG } from 'grifo-components';
import { CHARTERER, DRIVER, SHIPPING_COMPANY } from './drivers/DriversFieldsOptions';
export const buildFeatures = () => {
  const fleetPath = "/" + FLEET;
  const fleetConfigPath = fleetPath + '/' + CONFIG;
  const fleetConfigRoutes = [{
    ordination: '10.07.01',
    name: 'Marcas e Modelos',
    key: fleetConfigPath + "/vehicle_brands_models",
    routePath: fleetConfigPath + "/vehicle_brands_models",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "VehicleBrandsPage",
      import_path: "containers/vehicleBrands/VehicleBrandsPage"
    }
  }, {
    ordination: '10.07.02',
    name: 'Tipos de Veículos',
    key: fleetConfigPath + "/vehicle_type",
    routePath: fleetConfigPath + "/vehicle_type",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "VehicleTypesPage",
      import_path: "containers/vehicleTypes/VehicleTypesPage"
    }
  }, {
    ordination: '10.07.03',
    name: 'Carrocerias',
    key: fleetConfigPath + "/vehicle_bodywork",
    routePath: fleetConfigPath + "/vehicle_bodywork",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "VehicleBodyworksPage",
      import_path: "containers/vehicleBodyworks/VehicleBodyworksPage"
    }
  }];
  const routes = [{
    ordination: '10.01',
    name: 'Dashboard',
    key: fleetPath + "/dashboard",
    routePath: fleetPath + "/dashboard",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DashboardFleetPage",
      import_path: "containers/Dashboard"
    }
  }, {
    ordination: '10.02',
    name: 'Veículos',
    key: fleetPath + "/vehicles",
    routePath: fleetPath + "/vehicles",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "VehiclesPage",
      import_path: "containers/vehicles/VehiclesPage"
    },
    dividerAbove: true
  }, {
    ordination: '10.03',
    name: 'Reboques',
    key: fleetPath + "/trailers",
    routePath: fleetPath + "/trailers",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "TrailersPage",
      import_path: "containers/trailers/TrailersPage"
    }
  }, {
    ordination: '10.05',
    name: 'Freteiros',
    key: fleetPath + "/charterers",
    routePath: fleetPath + "/charterers",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DriversPage",
      import_path: "containers/drivers/DriversPage"
    },
    contentProp: {
      profile: CHARTERER
    },
    dividerAbove: true
  }, {
    ordination: '10.06',
    name: 'Transportadoras',
    key: fleetPath + "/shipping_companies",
    routePath: fleetPath + "/shipping_companies",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DriversPage",
      import_path: "containers/drivers/DriversPage"
    },
    contentProp: {
      profile: SHIPPING_COMPANY
    }
  }, {
    ordination: '10.07',
    name: 'Configurações',
    key: fleetConfigPath,
    routePath: fleetConfigPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    dividerAbove: true,
    children: fleetConfigRoutes
  }];
  return {
    ordination: '10',
    name: 'Frota',
    key: fleetPath,
    kind: 'module',
    status: 'active',
    routePath: fleetPath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'fleet',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: routes
  };
};
export const buildFeaturesSimple = () => {
  const fleetPath = "/" + FLEET;
  const routes = [{
    ordination: '10.02',
    name: 'Veículos',
    key: fleetPath + "/vehicles",
    routePath: fleetPath + "/vehicles",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "VehiclesPage",
      import_path: "containers/vehicles/VehiclesPage"
    },
    dividerAbove: true
  }, {
    ordination: '10.05',
    name: 'Freteiros',
    key: fleetPath + "/charterers",
    routePath: fleetPath + "/charterers",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DriversPage",
      import_path: "containers/drivers/DriversPage"
    },
    contentProp: {
      profile: CHARTERER
    },
    dividerAbove: true
  }];
  return {
    ordination: '10',
    name: 'Frota',
    key: fleetPath,
    kind: 'module',
    status: 'active',
    routePath: fleetPath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'fleet',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: routes
  };
};