import gql from "graphql-tag";
import { movementFragment } from "../fragments/Movement";
export const GQL_MOVEMENTS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $stockMovementFilter: StockMovementFilter) {    
    stockMovementsPaginated(first: $pageSize, after: $cursor, stockMovementFilter: $stockMovementFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...StockMovement
        }
      }
    }    
  }
  ${movementFragment}
`;
export const GQL_MOVEMENT_STORE = gql`
  mutation StoreStockMovement($attributes: [StockMovementAttributes!]!) {
    storeStockMovement(input: {attributes: $attributes}) { 
      movements {
        ...StockMovement
      }
      errors 
    } 
  }
  ${movementFragment}   
`;