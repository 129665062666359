import gql from "graphql-tag";
import { personFragment } from 'admin-front';
import { accountSimpleFragment } from "./Account";
import { negotiationFragment } from "./Negotiation";
export const avaiableNegotiationFragment = gql`
  fragment AvaiableNegotiation on AvaiableNegotiation {
    person {
      ...Person
    },
    accounts {
      ...AccountSimple,
    },
    negotiation {
      ...Negotiation
    },
    balanceToPay,
    balanceToReceive,
    maxOverdueToPay,
    maxOverdueToReceive
  }
  ${personFragment}
  ${accountSimpleFragment}
  ${negotiationFragment}
`;