import gql from "graphql-tag";
import { negotiationAccountFragment } from "./NegotiationAccount";
import { NegotiationPaymentFragment } from "./NegotiationPayment";
export const negotiationFragment = gql`
  fragment Negotiation on Negotiation {
    id,
    personId,
    operationId,
    addition,
    currentAssetId,
    deduction,
    value,
    transactionId,
    status,
    date,
    financialAccountId,
    negotiationPayments {
      ...NegotiationPayment
    },
    negotiationAccounts {
      ...NegotiationAccount
    }
  }
  ${negotiationAccountFragment}
  ${NegotiationPaymentFragment}
`;