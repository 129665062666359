import { sanitizeBackend } from 'grifo-components';
export const requestParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      paymentConditionId: values.paymentConditionId,
      paymentMethodId: values.paymentMethodId,
      pickupCompanyId: values.pickupCompany,
      currentEvent: values.currentEvent,
      currentDate: values.currentDate,
      requestProductUnits: values.requestProductUnits.map(object => ({
        quantity: object.quantity,
        productUnitId: object.productUnit[1]
      }))
    }
  };
};
export const requestDefaultBuilder = record => {
  const object = record || {};
  return {
    paymentConditionId: (object.paymentCondition || {}).id,
    paymentMethodId: (object.paymentMethod || {}).id,
    paymentCondition: object.paymentCondition || {},
    paymentMethod: object.paymentMethod || {},
    pickupCompany: (object.pickupCompany || {}).id,
    currentEvent: object.currentEvent,
    canEdit: object.canEdit,
    person: object.person,
    companyId: object.companyId,
    transactionId: object.transactionId || '',
    currentDate: sanitizeBackend(object.currentDate),
    requestProductUnits: object.requestProductUnits || [{}],
    requestIds: object.requestIds
  };
};
export const requesitionToInvoiceParamBuilder = record => {
  const object = record || {};
  return {
    requestId: object.id,
    paymentCondition: object.paymentCondition || {},
    paymentMethod: object.paymentMethod || {},
    pickupCompany: object.pickupCompany || {},
    currentEvent: object.currentEvent,
    canEdit: object.canEdit,
    person: object.person,
    companyId: object.companyId,
    transactionId: object.transactionId || '',
    currentDate: sanitizeBackend(object.currentDate),
    requestProductUnits: object.requestProductUnits || [{}],
    requestIds: object.requestIds
  };
};
export const loadProductSet = (record, form, index) => {
  if (!record || !form) return;
  const fieldsValue = form.getFieldsValue();
  if (!fieldsValue?.requestProductUnits?.[index]) return;
  const productUnit = fieldsValue.requestProductUnits[index] || {};
  const productUnitId = productUnit.productUnit?.id || productUnit.productUnitId;
  const productId = productUnit.product?.id || productUnit.productId;
  return [productId, productUnitId];
};