export const GENERATE_ALL_BANK_SLIPS = 'generate_all';
export const GENERATE_FIRST = 'generate_first';
export const NOT_GENERATE = 'not_generate';
export const OPENED = 'opened';
export const CLOSED = 'closed';
export const OVERDUE = 'overdue';
export const ERASED = 'erased';
export const IN_NEGOTIATION = 'in_negotiation';
export const ALL = 'all';
export const BANK_SLIP_PRINT_OPTIONS = [{
  value: GENERATE_ALL_BANK_SLIPS,
  label: 'Gerar para todos os pagamentos'
}, {
  value: GENERATE_FIRST,
  label: 'Gerar apenas para o primeiro pagamento'
}, {
  value: NOT_GENERATE,
  label: 'Não gerar os boletos agora'
}];
export const ACCOUNT_STATUS_OPTIONS = [{
  value: OPENED,
  label: 'Aberta'
}, {
  value: CLOSED,
  label: 'Liquidada'
}, {
  value: OVERDUE,
  label: 'Em atraso'
}, {
  value: ERASED,
  label: 'Apagada'
}, {
  value: IN_NEGOTIATION,
  label: 'Em Negociação'
}, {
  value: ALL,
  label: 'Todos'
}];