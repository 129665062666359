import gql from "graphql-tag";
import { fleetPersonSimpleFragment, fleetPersonFragment } from 'fleet-front';
import { fiscalOperationFragment } from "./Operation";
import { electronicDocumentFragment } from "./ElectronicDocument";
export const ManifestFragment = gql`
  fragment Manifest on Manifest  { 
    id,
    companyId,
    loadingState,
    unloadingState,
    transactionId,
    code,
    issuerType,
    carrierType,
    modal,
    dfeModel,
    dfeSeries,
    dfeNumber,
    cargoValue,
    tollValue,
    insuranceValue,
    measureType,
    cargoGrossWeight,
    operationId,
    situation,
    issuanceDate,
    operation {
      ...FiscalOperation
    }
    vehicleOwnerId {
      ...FleetPersonSimple
    },
    driverId {
      ...FleetPerson
    },
    electronicDocument {
      ...ElectronicDocument
    },  
    vehicleOwnerType
  } 
  ${fiscalOperationFragment}  
  ${fleetPersonFragment}
  ${fleetPersonSimpleFragment}
  ${electronicDocumentFragment}
`;