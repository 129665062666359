import gql from "graphql-tag";
import { movementFragment } from "../fragments/Movement";
import { accountFragment } from "../fragments/Account";
export const GQL_ADVANCE_MOVEMENT = gql`
  mutation AdvanceMovement($attributes: AccountAttributes!, $currentAssetAccountId: ID!) {
    advanceMovement(input: {attributes: $attributes, currentAssetAccountId: $currentAssetAccountId}) { 
      currentAssetMovement { 
        ...Movement
      },
      counterpartAccount { 
        ...Account
      }, 
      errors 
    } 
  }
  ${movementFragment}
  ${accountFragment}  
`;