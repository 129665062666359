import gql from "graphql-tag";
import { avaiableNegotiationFragment } from "../fragments/AvaiableNegotiation";
import { negotiationFragment } from "../fragments/Negotiation";
import { reportFragment } from 'admin-front';
export const GQL_AVAILABLE_NEGOTIATIONS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $search: String) {
    negotiationsPaginated(first: $pageSize, after: $cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
      edges { 
        cursor
        node {
          ...AvaiableNegotiation
        }
      }
    }
  }
  ${avaiableNegotiationFragment}
`;
export const GQL_AVAIABLE_NEGOTIATIONS_BY_PERSON = gql`
  query GetByPerson($personId: ID) {    
    avaiableNegotiations(personId: $personId) {
      ...AvaiableNegotiation
    }
  }
  ${avaiableNegotiationFragment}
`;
export const GQL_NEGOTIATIONS_BY_PERSON = gql`
  query GetByPerson($personId: ID) {    
    negotiations(personId: $personId) {
      ...Negotiation
    }
  }
  ${negotiationFragment}
`;
export const GQL_NEGOTIATION_STORE = gql`
  mutation StoreNegotiation($attributes: NegotiationAttributes!) {
    storeNegotiation(input: {attributes: $attributes}) { 
      negotiation {
        ...Negotiation
    }, 
      errors 
    } 
  }
  ${negotiationFragment}   
`;
export const GQL_PRINT_NEGOTIATION_RECEIPT = gql`
  query PrintNegotiationReceipt($negotiationId: String, $transactionId: String, $accountId: String, $receiptType: String) {    
    printNegotiationReceipt(negotiationId: $negotiationId, transactionId: $transactionId, accountId: $accountId, receiptType: $receiptType) {
      ...Report
    }
  }
  ${reportFragment}
`;
export const GQL_CANCEL_NEGOTIATION = gql`
mutation CancelNegotiation($id: ID!, $status: String, $reason: String) { 
  cancelNegotiation(input: {id: $id, status: $status, reason: $reason}) {
    id,
    errors
  }
}
`;