export const round = (value, precision = 2) => {
  const factorOfTen = Math.pow(10, precision);
  return Math.round(value * factorOfTen) / factorOfTen;
};
export const trunc = value => {
  return Math.trunc(Math.fround(value * 100)) / 100;
};
function preciseMultiply(value) {
  return Math.round(Math.round(value * 10000) / 100) / 100;
}
export const precisionType = (value, precision = 2, kind = 'rounder') => {
  return kind == 'rounder' ? preciseMultiply(value) : trunc(value);
};