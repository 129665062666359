import gql from "graphql-tag";
import { NcmFragment } from "./Ncm";
export const taxProfileNcmFragment = gql`
  fragment TaxProfileNcm on TaxProfileNcm { 
    id,
    goodsOrigin,
    tecRate,
    kind,
    ncmId,
    ncm {
      ...Ncm
    }
  }
  ${NcmFragment}
`;